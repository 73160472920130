<template>
  <div class="container content-p d-flex flex-column flex-grow-1">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="campusData === undefined">
      <h4 class="alert-heading">Error fetching campus data</h4>
      <div class="alert-body">
        No campus found with this campus id. Check
        <b-link class="alert-link" :to="{ name: 'apps-sites-campus-list-pending' }"> Campus List </b-link>
        for other campuss.
      </div>
    </b-alert>

    <template v-if="campusData">
      <campus-edit-modal :edit-campus-active.sync="editCampusActive" :campus-data="campusData" @refetch-data="fetchData" />

      <b-row class="mb-1">
        <b-col cols="12" md="12">
          <campus-view-header :campus-data="campusData" :edit-campus-active.sync="editCampusActive" />
        </b-col>
      </b-row>

      <b-row class="d-flex flex-grow-1">
        <b-col cols="12" lg="7" md="7" class=""> </b-col>
        <b-col cols="12" lg="5" md="5" class="">
          <!-- <campus-view-section-list :campus-data.sync="campusData" :create-campus-section-active.sync="createCampusSectionActive" /> -->
          <campus-view-map :campus-data.sync="campusData" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref } from '@vue/composition-api';
import { BRow, BCol, BAlert, BLink, BButton, BCard } from 'bootstrap-vue';
import CampusViewHeader from './CampusViewHeader.vue';
import CampusEditModal from '../edit/CampusEditModal.vue';
import CampusViewMap from './CampusViewMap.vue';
import { avatarText } from '@core/utils/filter';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BButton,

    // Local Components
    CampusViewHeader,
    CampusEditModal,
    CampusViewMap,

    avatarText,

    LMap,
    LTileLayer,
    LMarker,
  },
  metaInfo() {
    const title = this.campusData ? `${this.campusData.name}` : 'View Campus';
    return {
      title,
    };
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [51.505, -0.159],
      markerLatLng: [51.504, -0.159],
      icon: L.icon({
        iconUrl: 'https://cdn.intellectualpoint.com/mgmt-mapbox-marker-icon.png',
        iconSize: [55, 55],
        iconAnchor: [16, 37],
      }),
    };
  },
  setup() {
    const campusData = ref(null);

    const editCampusActive = ref(false);
    const createCampusSectionActive = ref(false);

    const fetchData = () => {
      store
        .dispatch('siteStoreModule/fetchCampus', {
          id: router.currentRoute.params.id,
          queryParams: { populate: 'campus' },
        })
        .then((response) => {
          campusData.value = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            campusData.value = undefined;
          }
        });
    };

    fetchData();

    return {
      campusData,
      fetchData,
      editCampusActive,
      createCampusSectionActive,
    };
  },
};
</script>
<style></style>
