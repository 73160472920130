<template>
  <div>
    <div
      @contextmenu.prevent="$refs.campusViewEditMenu.open"
      :style="`${calculateBorderColor(campusColor)} padding: 0.075rem`"
      class="mb-1 rounded position-relative overflow-hidden drop-shadow-default"
    >
      <div class="rounded" style="background: #191a22">
        <div class="d-flex justify-content-between card-body rounded z-2 position-relative" :style="calculateBackgroundColor(campusColor)">
          <div class="d-flex align-items-center">
            <b-avatar
              :src="campusData.avatar"
              :text="avatarText(campusData.name)"
              size="100px"
              variant="light-info"
              class="mr-1"
              style="margin-top: 3px"
              rounded
            />
            <div class="d-flex flex-column align-items-start justify-content-between">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center">
                  <h4 class="font-weight-bolder tracking-tighter mr-50" style="font-size: 1.85rem; line-height: 2.2rem; margin-bottom: 2px">
                    {{ campusData.name }}
                  </h4>
                  <feather-icon
                    icon="EditIcon"
                    size="13"
                    class="cursor-pointer text-muted"
                    style="margin-top: 1px"
                    @click="$emit('update:edit-campus-active', true)"
                  />
                </div>

                <span class="d-flex align-items-center text-muted mb-50">{{ campusData.description }}</span>
              </div>

              <div class="d-flex align-items-center">
                <b-badge class="profile-badge mr-25" :variant="resolveCampusStatusVariant(campusData.status)">
                  {{ title(campusData.status) }}
                </b-badge>
                <b-badge class="profile-badge" :variant="resolveCampusTypeVariant(campusData.type)">
                  {{ title(campusData.type) }}
                </b-badge>
              </div>
            </div>
          </div>

          <div class="d-flex w-50">
            <img
              :src="campusData.pictureUrl"
              class="position-absolute float-right right-0 top-0"
              style="width: 50%; height: 100%; object-fit: cover; top: -0rem; opacity: 0.8"
            />
            <div
              class="position-absolute h-100"
              style="
                width: 100%;
                background: linear-gradient(to right, rgba(37, 38, 46, 1) 0%, rgba(255, 255, 255, 0) 100%);
                object-fit: cover;
                top: 0rem;
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
    <vue-context ref="campusViewEditMenu">
      <li>
        <a class="d-flex align-items-center justify-content-between w-100" @click="$emit('update:edit-campus-active', true)">
          <span>Edit</span>
          <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol, BBadge, BLink } from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import useCampus from '../useCampus';
import { title } from '@core/utils/filter';
import useAppConfig from '@core/app-config/useAppConfig';
import VueContext from 'vue-context';
import store from '@/store';
import { hexToRGB } from '@/libs/utils/color';
import _ from 'lodash';

const { skin } = useAppConfig();

export default {
  components: {
    BLink,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    VueContext,
  },
  props: {
    campusData: {
      type: Object,
      required: true,
    },
    editCampusActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    campusColor() {
      if (this.campusData.metadata && this.campusData.metadata.design && this.campusData.metadata.design.color) {
        return this.campusData.metadata.design.color;
      }
      return '#5b5b5e';
    },
  },
  methods: {
    archiveCampus() {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to archive this campus?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          store
            .dispatch('rosterStoreModule/updateCampus', {
              campusId: this.campusData.id,
              campusData: { status: 'archived' },
            })
            .then((response) => {
              // this.refetchData();
            });
        }
      });
    },
    calculateBackgroundColor(hex) {
      const rgb = hexToRGB(hex);
      return `background: linear-gradient(200deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.35) 0%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.05) 93.34%)`;
    },
    calculateBorderColor(hex) {
      const rgb = hexToRGB(hex);
      return `background: linear-gradient(190deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9) 0%, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.03) 100%);`;
    },
  },
  mounted() {
    console.log(this.campusData);
  },
  setup() {
    const { resolveCampusStatusVariant, resolveCampusTypeVariant } = useCampus();
    return {
      avatarText,
      resolveCampusStatusVariant,
      resolveCampusTypeVariant,
      title,
      skin,
    };
  },
};
</script>

<style></style>
