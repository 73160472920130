import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';
import moment from 'moment';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default function useCampus() {
  // Use toast
  const toast = useToast();

  const refCampusListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'type', sortable: true },
    { key: 'status', sortable: true },
    {
      key: 'address',
      label: 'Address',
      formatter: (value) => {
        if (!value.street1) return '';
        const { street1, street2, city, state, zip, country } = value;
        return `${street1}${street2 ? `, ${street2}` : ''}, ${city}, ${state} ${zip}, ${country}`;
      },
      sortable: true,
    },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalCampus = ref(0);
  const currentPage = ref(1);
  const totalPageCount = ref(0);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);

  const typeFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refCampusListTable.value ? refCampusListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCampus.value,
    };
  });

  const refetchData = () => {
    refCampusListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, typeFilter, statusFilter], () => {
    refetchData();
  });

  const fetchCampusList = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,
      populate: '',
    };

    if (typeFilter.value) {
      pl.type = typeFilter.value;
    }
    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    store
      .dispatch('siteStoreModule/fetchCampusList', pl)
      .then((response) => {
        const { results, totalResults, totalPages } = response.data;

        callback(results);
        totalCampus.value = totalResults;
        totalPageCount.value = totalPages;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching campuses',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // archived, internal, public

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCampusTypeVariant = (type) => {
    if (type === 'physical') return 'light-warning';
    if (type === 'virtual') return 'light-info';
    return '';
  };

  const resolveCampusStatusVariant = (status) => {
    if (status === 'created') return 'light-secondary';
    if (status === 'active') return 'light-success';
    if (status === 'archived') return 'light-danger';
    return 'info';
  };

  const statusOptions = [
    { label: 'Created', value: 'created' },
    { label: 'Active', value: 'active' },
    { label: 'Archived', value: 'archived' },
  ];

  const typeOptions = [
    { label: 'Physical', value: 'physical' },
    { label: 'Virtual', value: 'virtual' },
  ];

  return {
    fetchCampusList,
    tableColumns,
    perPage,
    currentPage,
    totalPageCount,
    totalCampus,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCampusListTable,

    resolveCampusTypeVariant,
    resolveCampusStatusVariant,
    refetchData,

    typeOptions,
    statusOptions,

    // Extra Filters
    typeFilter,
    statusFilter,
  };
}
