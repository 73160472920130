<template>
  <l-map style="height: 100%; width: 100%; border-radius: 0.5rem" :zoom="zoom" :center="center">
    <l-tile-layer :url="url"></l-tile-layer>
    <l-marker :lat-lng="markerLatLng" :icon="icon"></l-marker>
  </l-map>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  metaInfo() {
    const title = this.campusData ? `${this.campusData.name}` : 'View Campus';
    return {
      title,
    };
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [39.034214, -77.4030771],
      markerLatLng: [39.034214, -77.4030771],
      icon: L.icon({
        iconUrl: 'https://cdn.intellectualpoint.com/mgmt-mapbox-marker-icon.png',
        iconSize: [55, 55],
        iconAnchor: [16, 37],
      }),
      campusData: null,
      editCampusActive: false,
      createCampusSectionActive: false,
    };
  },
  methods: {
    fetchCampusData() {
      store
        .dispatch('siteStoreModule/fetchCampus', {
          id: router.currentRoute.params.id,
          queryParams: { populate: 'campus' },
        })
        .then((response) => {
          this.campusData = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.campusData = undefined;
          }
        });
    },
  },
  created() {
    this.fetchCampusData();
  },
};
</script>
<style></style>
